import React from "react";
import style from './recipe.module.css'

const Recipe = ({title, calories, image, ingredients}) => {
    return(
        <div className={style.recipe}>
            <h1>{title}</h1>
            <ol className="list-group">
                {ingredients.map(ingredient => (
                    <li className="list-group-item">{ingredient.text}</li>
                ))}
            </ol>
            <p>Calories: {calories}</p>
            <img className={style.recipe} src={image} alt="" />
        </div>
    );
}

export default Recipe;